/* eslint-disable no-param-reassign, no-shadow */

import types from "./types";

import { default as fetchProductCatalog } from "@/services/fetchProductCatalog.js";
import { default as sendInfoData } from "@/services/sendInfoData.js";
import { default as checkDeviceInfo } from "@/services/checkDeviceInfo.js";
import { default as print } from "@/services/print.js";
import { default as logout } from "@/services/logout.js";

const state = {
  isFetching: false,
  isError: false,
  isMenuFetched: false,
  errorMsg: null,
  productCatalog: {},
  reportCatalog: {},
  catalog: {},
  info: {},
};

const getters = {
  [types.getters.PRODUCT_CATALOG]: (state) => state.productCatalog,
  [types.getters.IS_MENU_FETCHED]: (state) => state.isMenuFetched,
};

const actions = {
  [types.actions.PRINT]: async(ctx, data) => {
    try {
      const printerResponse = await print(data);
      if(printerResponse.config) {
        //strip content from log output
        printerResponse.config.data = "--removed--"
      }
      sendInfoData({
        issuer: "Printer "+data.vtcTerminalId, 
        data: printerResponse
      });
      return true; //Printing successful
    } catch (error) {
      if(error.config) {
        error.config.data = "--removed--"
      }
      sendInfoData({
        issuer: "PrinterError "+data.vtcTerminalId, 
        data: error
      });
      return false; //Printing failed
    }
  },

  [types.actions.SEND_INFO_TO_API]: (ctx, data) => {
    return sendInfoData(data);
  },

  [types.actions.FETCH_PRODUCT_CATALOG]: (ctx, data) => {
    ctx.commit(types.mutations.RECEIVE_PRODUCT_CATALOG);
    return fetchProductCatalog(data).then(
      (response) => {
        ctx.commit(types.mutations.RECEIVE_PRODUCT_CATALOG_SUCCESS, response);
      },
      (error) => {
        ctx.commit(types.mutations.RECEIVE_PRODUCT_CATALOG_FAILED, error);
      }
    );
  },
  [types.actions.CHECK_DEVICE_INFO]: (ctx, feature) => {
    return checkDeviceInfo(feature)
  }, 
  [types.actions.LOGOUT]: () => {
    return logout();
  }
};

const mutations = {
  [types.mutations.RECEIVE_PRODUCT_CATALOG]: (state) => {
    state.isFetching = true;
  },

  [types.mutations.SET_INFO]: (state, data) => {
    state.info = data;
  },

  [types.mutations.RECEIVE_PRODUCT_CATALOG_SUCCESS]: (state, response) => {
    state.catalog = response.data.vtcMenu;
    state.isFetching = false;
    state.isError = false;
    state.isMenuFetched = true;
  },

  [types.mutations.RECEIVE_PRODUCT_CATALOG_FAILED]: (state, error) => {
    state.isFetching = false;
    state.isError = true;
    state.errorMsg = error;
  },
};

export default { state, getters, actions, mutations };
