<template>
<div id="app">
  <router-view />
</div>
</template>

<script>
import { default as getConfigValue } from "@/utils/config.js"
import { keycloakAdapter } from "@/main.js"
export default {
  data() {
    return {
      msg : "This is the app site",
    }
  },
  mounted() {
    if (getConfigValue("loginRequired")) {
      //https://socket.io/docs/v4/client-options/#auth
      this.$socket.client.auth = {token : keycloakAdapter.token}
      this.$socket.client.open()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/ci.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
}

.vertical-center {
  position: absolute;
  text-align: center;
}

.dialog_button {
  min-width: 120px;
  min-height: 40px;
  margin: 5px;
  border-radius: 10px;
  border-color: black;
}

</style>
